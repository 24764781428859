import { Environment } from '@lms/shared/models';

export const environment: Environment = {
  appVersion: 'V-20250211-1539',
  appVersionSync: true,
  production: true,
  envName: '',
  jwPlayerLibUrl: 'https://cdn.jwplayer.com/libraries/nQQhXkpn.js',
  build: 'dashboard',
  theme: 'cfi',
  apiUrl: 'https://api.corporatefinanceinstitute.com/api/',
  adminPanelUrl: 'https://lms.corporatefinanceinstitute.com/',
  dashboardPanelUrl: 'https://learn.corporatefinanceinstitute.com/',
  teamsUrl: 'https://teams.corporatefinanceinstitute.com/',
  welcomeSurveyUrl: 'https://corporatefinanceinstitute.typeform.com/to/dclnjw1t',
  welcomeTourEnabled: true,
  recaptchaClientKey: '6LdObNEZAAAAAGpzwyKBZ3dsR0tognCfHuaRXfDQ',
  hotjarTrackingCode: '3486471',
  stripePublishableKey: 'pk_live_o6D5NmMzDpsfeDNY13bq1iE2',
  twigAdminEnabled: true,
  paypalClientId: 'AWYxpkMqS7b4C1YtAaDHayoWPW2g8tiHiHlo2jqiZA5yNplY_xlGGxflmO67hVWEvqAI4um_yJNIgd2K',
  serviceWorker: false,
  helpScoutId: '0f828980-e91a-4682-b173-a4fc78822dc8', 
  helpScoutB2bStudentId: '39daeda8-d830-4bc6-8baa-8e3ea5ea394c',
  helpScoutFICoursePlayerId: '7f849c00-f6a3-4d18-9215-1b84be0c511e',
  helpScoutFIDashboardId: 'b9fc6079-0a1d-4b88-992d-8b1615abb48c',
  gtmCode: 'NVD2HG2',
  gtmUrl: 'https://direct.corporatefinanceinstitute.com/vdnfhfgo.js?st=',
  marketingSiteUrl: 'https://corporatefinanceinstitute.com/',
  cookieDomain: '.corporatefinanceinstitute.com',
  cancellationSurveyUrl: 'https://corporatefinanceinstitute.typeform.com/feedback',
  klaviyoPublicKey: 'TsKLPy',
  klaviyoTrackingDomains: [
    'corporatefinanceinstitute.com',
    'cart.corporatefinanceinstitute.com',
    'macabacus.com',
    'lp.corporatefinanceinstitute.com',
  ],
  elasticSearchEndpoint: 'https://search.corporatefinanceinstitute.com/api/public',
  onePageCheckout: '1',
  maxioCreditCardsEnabled: true,
  maxioPublicKey: 'chjs_jrnt4hsc9z7qd2j3swn8jf4x',
  maxioServerHost: 'https://cfi-education-inc.chargify.com',
cookieyesId: '1b96de0cdb9029f4aa9e423b',
careersRolesMainCourseUrl: 'careers-in-finance',
  careersRolesFocusedCategoryUrl: 'career',
  careersRolesRecommendedResourcesIds: [
    'P176',
    'P184',
    'P209315',
    'P209830',
    'P20745',
    'P210257',
    'P178',
    'P174',
    'P1125',
    'P11629',
    'P474',
    'P210090',
    'P12828',
    'P2825',
  ],
  datadog: {
    clientToken: 'pub12ec9592dfee91bb3b046831377514f1',
    site: 'datadoghq.com',
    service: 'lmsfront',
    env: 'prod',
    forwardErrorsToLogs: false,
    sampleRate: 100
  },
  hirationKey: 'c7ef5ei6e'
};
